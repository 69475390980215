import {
  createContext,
  type Dispatch,
  type ReactNode,
  useContext,
  useReducer,
} from 'react';
import { type BaseOptionType } from '@wearemotivated/design-system/redesign/types';
import { parseProductData } from '@/redesign/lib/utils/product';
import type { DosageType } from '@/redesign/types';

type PricingCalculatorProviderProps = {
  productVariants: ReturnType<typeof parseProductData>;
  children: ReactNode;
};

type PricingCalculatorState = {
  values: {
    strength: StrengthOption;
    product: ProductOption;
    quantity: QuantityOption;
    frequency: FrequencyOption;
  };
  options: {
    strength: StrengthOption[];
    product: ProductOption[];
    quantity: QuantityOption[];
    frequency: FrequencyOption[];
  };
};

type PricingCalculatorContextType = {
  values: {
    strength: PricingCalculatorState['values']['strength'] | null;
    product: PricingCalculatorState['values']['product'] | null;
    quantity: PricingCalculatorState['values']['quantity'] | null;
    frequency: PricingCalculatorState['values']['frequency'] | null;
  };
} & Pick<PricingCalculatorState, 'options'>;

export interface StrengthOption extends BaseOptionType {
  category?: string;
}

export interface ProductOption extends BaseOptionType {
  manufacturerType: string;
}

export interface QuantityOption extends BaseOptionType {
  value: string;
  numericalValue: number | null;
  label: string;
  default: boolean;
}

export interface FrequencyOption {
  value: string;
  numericalValue: number | null;
  label: string;
  default: boolean;
}

type ActionType =
  | { action: 'STRENGTH_CHANGE'; data: StrengthOption }
  | { action: 'PRODUCT_CHANGE'; data: ProductOption }
  | { action: 'QUANTITY_CHANGE'; data: string }
  | { action: 'FREQUENCY_CHANGE'; data: string };

const PricingCalculatorContext = createContext<
  {
    dispatchCalculatorEvent: Dispatch<ActionType>;
  } & PricingCalculatorContextType
>({
  dispatchCalculatorEvent: (params: ActionType) => {
    console.error(
      'error: website/dispatchCalculatorEvent - default dispatch',
      params,
    );
  },
  values: {
    strength: null,
    product: null,
    quantity: null,
    frequency: null,
  },
  options: {
    strength: [],
    product: [],
    quantity: [],
    frequency: [],
  },
});

const reducer =
  (productVariants: ReturnType<typeof parseProductData>) =>
  (
    state: PricingCalculatorState,
    { action, data }: ActionType,
  ): PricingCalculatorState => {
    switch (action) {
      case 'STRENGTH_CHANGE': {
        const selectedStrength = data;
        const selectedDosage = Array.from(
          productVariants.dosages.values(),
        ).find((dosage) => dosage.id === selectedStrength.value) as DosageType;
        const productOptions = Array.from(selectedDosage.products.values()).map(
          (product) =>
            ({
              label: product.id,
              value: product.id,
              manufacturerType: product.manufacturerType,
            }) as ProductOption,
        );
        const selectedProduct = productOptions.some(
          (po) => po.value === state.values.product.value,
        )
          ? state.values.product
          : productOptions[0];

        const nextState = {
          options: {
            ...state.options,
            product: productOptions,
          },
          values: {
            ...state.values,
            strength: selectedStrength,
          },
        };

        return reducer(productVariants)(nextState, {
          action: 'PRODUCT_CHANGE',
          data: selectedProduct,
        });
      }
      case 'PRODUCT_CHANGE': {
        const selectedStrength = state.values.strength;
        const selectedProduct = data;
        const quantityOptions = Array.from(
          productVariants.dosages
            .get(selectedStrength.value)
            ?.products.get(selectedProduct.value)
            ?.quantities.values() ?? [],
        ).map((quantity) => ({
          label: quantity?.name,
          value: quantity.id,
          default: quantity.default,
          numericalValue: quantity.numericalValue,
        }));

        const lastQuantity = state.values.quantity;
        const selectedQuantity =
          quantityOptions.find((o) =>
            lastQuantity.numericalValue
              ? o.numericalValue === lastQuantity.numericalValue
              : o.default,
          ) ?? quantityOptions[0];

        const nextState = {
          options: {
            ...state.options,
            quantity: quantityOptions,
          },
          values: {
            ...state.values,
            product: selectedProduct,
          },
        };

        return reducer(productVariants)(nextState, {
          action: 'QUANTITY_CHANGE',
          data: selectedQuantity.value,
        });
      }
      case 'QUANTITY_CHANGE': {
        const selectedStrength = state.values.strength;
        const selectedProduct = state.values.product;
        const selectedQuantityId = data;
        const selectedQuantity = state.options.quantity.find(
          (o) => o.value === selectedQuantityId,
        ) as QuantityOption;
        const frequencyOptions = Array.from(
          productVariants.dosages
            .get(selectedStrength.value)
            ?.products.get(selectedProduct.value)
            ?.quantities.get(selectedQuantityId)
            ?.frequencies.values() ?? [],
        ).map((frequency) => ({
          label: frequency.name,
          value: frequency.id,
          default: frequency.default,
          numericalValue: frequency.numericalValue,
        }));

        const lastFrequency = state.values.frequency;
        const selectedFrequency =
          frequencyOptions.find((o) =>
            lastFrequency.numericalValue
              ? o.numericalValue === lastFrequency.numericalValue
              : o.default,
          ) ?? frequencyOptions[0];

        const nextState = {
          options: {
            ...state.options,
            frequency: frequencyOptions,
          },
          values: {
            ...state.values,
            quantity: selectedQuantity,
          },
        };

        return reducer(productVariants)(nextState, {
          action: 'FREQUENCY_CHANGE',
          data: selectedFrequency.value,
        });
      }
      case 'FREQUENCY_CHANGE': {
        const selectedFrequency = data;
        const frequency = state.options.frequency.find(
          (f) => f.value === selectedFrequency,
        ) as FrequencyOption;
        return {
          ...state,
          values: {
            ...state.values,
            frequency,
          },
        };
      }
    }

    return state;
  };

const initializeReducer = (
  productVariants: ReturnType<typeof parseProductData>,
): PricingCalculatorState => {
  const defaultProducts = Array.from(
    productVariants.defaultDosage?.products?.values() ?? [],
  );
  const defaultProduct = defaultProducts[0];
  const defaultQuantities = Array.from(defaultProduct?.quantities.values());
  const defaultQuantity =
    defaultQuantities.find((q) => q.default) ?? defaultQuantities?.[0];
  const defaultFrequencies = Array.from(defaultQuantity?.frequencies.values());
  const defaultFrequency =
    defaultFrequencies.find((f) => f.default) ?? defaultFrequencies?.[0];

  const strengthOptions = Array.from(productVariants.dosages.values())
    .sort((a, b) => (a.numericalValue ?? 0) - (b.numericalValue ?? 0))
    .map((dosage) => {
      return {
        label: dosage.id,
        value: dosage.id,
        category: dosage.products.values().next()?.value?.categoryName,
      };
    });

  const productOptions = defaultProducts.map(
    (product) =>
      ({
        label: product.id,
        value: product.id,
        manufacturerType: product.manufacturerType,
      }) as ProductOption,
  );

  const quantityOptions = defaultQuantities.map((quantity) => ({
    label: quantity?.name,
    value: quantity.id,
    default: quantity.default,
    numericalValue: quantity.numericalValue,
  }));

  const frequencyOptions = defaultFrequencies.map((frequency) => ({
    label: frequency.name,
    value: frequency.id,
    default: frequency.default,
    numericalValue: frequency.numericalValue,
  }));

  return {
    values: {
      strength: strengthOptions.find(
        (s) => s.value === productVariants.defaultDosage?.id,
      ) as StrengthOption,
      product: productOptions.find(
        (p) => p.value === defaultProduct?.id,
      ) as ProductOption,
      quantity: quantityOptions.find(
        (q) => q.value === defaultQuantity?.id,
      ) as QuantityOption,
      frequency: frequencyOptions.find(
        (f) => f.value === defaultFrequency?.id,
      ) as FrequencyOption,
    },
    options: {
      strength: strengthOptions,
      product: productOptions,
      quantity: quantityOptions,
      frequency: frequencyOptions,
    },
  };
};

const PricingCalculatorProvider = ({
  productVariants,
  children,
}: PricingCalculatorProviderProps) => {
  const [state, dispatch] = useReducer(
    reducer(productVariants),
    productVariants,
    initializeReducer,
  );

  return (
    <PricingCalculatorContext.Provider
      value={{
        dispatchCalculatorEvent: dispatch,
        ...state,
      }}>
      {children}
    </PricingCalculatorContext.Provider>
  );
};

const usePricingCalculator = () => {
  return useContext(PricingCalculatorContext);
};

export { PricingCalculatorProvider, usePricingCalculator };
